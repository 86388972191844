import React, { useEffect, useState } from 'react';
import './NavbarToggler.css';
import { FaBars } from 'react-icons/fa';
import classnames from 'classnames';
import { addAlphaToHex } from '../../utils';

export default function NavbarToggler({ onClick, className }) {
	const [color, setColor] = useState();

	useEffect(() => {
		const iconsColor = getComputedStyle(document.documentElement)
			.getPropertyValue('--IconsColor')
			.replace('#', '');
		setColor(addAlphaToHex(iconsColor, 0.3));
	}, []);
	return (
		<div
			style={{ borderColor: color }}
			className={classnames('nav-toggler', { [className]: className })}
		>
			<button onClick={onClick}>
				<FaBars />
			</button>
		</div>
	);
}
