import React, { useContext, useReducer, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { Button, Modal, Form, Container, Row, Col, Card, InputGroup, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { Store } from '../../Store';
import { getError } from '../../utils';
import LoadingBox from '../../components/LoadingBox';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import es from 'date-fns/locale/es';
import moment from 'moment';
import { Calendar } from 'react-calendar';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'FETCH_SUCCESS':
		return { ...state, loadingFetch: false };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function NewEvent() {
	const navigate = useNavigate();
	const { state } = useContext(Store);
	const { userInfo } = state;

	const [{ loadingFetch, loadingCreate }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		loadingCreate: true,
		error: ''
	});

	const [tema, setTema] = useState('');
	const [locacion, setLocacion] = useState('');
	const [capacidad, setCapacidad] = useState(0);
	const [fechaInicio, setFechaInicio] = useState(new Date());
	const [duration, setDuration] = useState(30);
	let dates = [];
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	function handleKeyDown(event) {
		const specialCharacters = /[$&+,:;=?@#|'<div>^*%!]/; //see what characters we want to avoid
		if (specialCharacters.test(event.key)) {
			event.preventDefault();
		}
	}

	const addEventHandler = async () => {
		dispatch({ type: 'CREATE_REQUEST' });
		switch (repeatOption) {
		case 'none':
			{
				dates.push(fechaInicio);
			}
			break;
		case 'daily':
			{
				dates = getDatesByWeekdays(fechaInicio, endDate, availableWeekDays);
			}
			break;
		case 'weekday':
			{
				dates = getDatesByWeekdays(fechaInicio, endDate, ['L', 'M', 'X', 'J', 'V']);
			}
			break;
		case 'weekly':
			{
				dates = getDatesByWeekdays(fechaInicio, endDate, selectedDays);
			}
			break;
		}
		if (dates.length > 0) {
			try {
				await axios.post(
					'/api/event',
					{
						dates: dates,
						duration: duration,
						capacity: capacidad,
						subject: tema,
						location: locacion
					},
					{
						headers: { Authorization: `Bearer ${userInfo.token}` }
					}
				);
				dispatch({ type: 'CREATE_SUCCESS' });
				toast.success('evento creado');
				navigate('/AdminScreen/eventList');
			} catch (error) {
				dispatch({ type: 'CREATE_FAIL' });
				toast.error(getError(error));
			}
		} else
			toast.error(
				'Debe elegir una fecha de fin acorde y filtros que permitan ingresar al menos una fecha.'
			);
		/*try {
      let body = {
        eventStartDateTime: fechaInicio,
        eventStartDate: moment(fechaInicio).format('DD/MM/yyyy'),
        eventStartTime: moment(fechaInicio).format('HH:mm'),
        duration: duration,
        capacity: capacidad,
      };
      if (locacion !== '') {
        body = { ...body, location: locacion };
      }
      if (tema !== '') {
        body = { ...body, subject: tema };
      }
      dispatch({ type: 'CREATE_REQUEST' });
      await axios.post('/api/event', body, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      toast.success('Fecha levantada');
      navigate(`/AdminScreen/eventList`);
    } catch (ex) {
      toast.error(getError(ex));
    }*/
	};

	//Manejamos periodicas
	const [repeatOption, setRepeatOption] = useState('none');
	const [selectedDays, setSelectedDays] = useState([]);
	const [endDate, setEndDate] = useState(new Date());
	const availableWeekDays = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];

	const handleRepeatOptionChange = (event) => {
		setRepeatOption(event.target.value);
	};

	//Cada uno de los dias de la semana entran o salen de la lista
	const handleSelectedDaysChange = (event) => {
		const day = event.target.value;
		if (selectedDays.includes(day)) {
			setSelectedDays(selectedDays.filter((d) => d !== day));
		} else {
			setSelectedDays([...selectedDays, day]);
		}
	};

	//Funcion para obtener los dias entre fechas
	function getDatesByWeekdays(startDate, endDate, weekdays) {
		const weekdaysMap = {
			L: 1,
			M: 2,
			X: 3,
			J: 4,
			V: 5,
			S: 6,
			D: 0
		};
		const selectedWeekdays = weekdays.map((weekday) => weekdaysMap[weekday.toUpperCase()]);
		const datesInRange = [];

		let currentDate = moment(startDate).startOf('day');
		const lastDate = moment(endDate).startOf('day');

		while (currentDate.isSameOrBefore(lastDate)) {
			if (selectedWeekdays.includes(currentDate.day())) {
				const dateToAdd = moment(currentDate)
					.hour(moment(startDate).hour())
					.minute(moment(startDate).minute())
					.toDate();
				datesInRange.push(dateToAdd);
			}
			currentDate.add(1, 'day');
		}

		return datesInRange;
	}

	return (
		<div>
			<div>
				{loadingCreate || (loadingFetch && <LoadingBox></LoadingBox>)}

				<Modal show={showModal} onHide={handleClose} animation={true}>
					<Modal.Header closeButton>
						<Modal.Title>Crear evento</Modal.Title>
					</Modal.Header>
					<Modal.Body>Seguro desea crear evento?</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button onClick={addEventHandler}>Confirmar</Button>
					</Modal.Footer>
				</Modal>
			</div>
			<Container className="large-container">
				<Helmet>
					<title>Crear evento</title>
				</Helmet>
				<Container fluid>
					<Row className="mt-5 vh-100 d-flex justify-content-center">
						<Col md={8} lg={6} xs={12}>
							<div className="borderLine"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">Crear evento</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3" controlid="tema">
													<Form.Label>Tema</Form.Label>
													<Form.Control
														type="text"
														required
														onKeyDown={handleKeyDown}
														onChange={(e) => setTema(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group className="mb-3" controlid="locacion">
													<Form.Label>Locacion</Form.Label>
													<Form.Control
														type="text"
														required
														onKeyDown={handleKeyDown}
														onChange={(e) => setLocacion(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Label>Capacidad</Form.Label>
												<InputGroup className="mb-3" controlid="capacidad">
													<Form.Control
														type="number"
														required
														defaultValue="1"
														min="1"
														onChange={(e) => setCapacidad(e.target.value)}
													/>
												</InputGroup>
												<Form.Label>Fecha y hora de inicio</Form.Label>
												<InputGroup className="mb-3" controlid="fechaInicio">
													<br></br>
													<DateTimePicker
														onChange={setFechaInicio}
														value={fechaInicio}
														minDate={new Date()}
														clearIcon={null}
														required={true}
														dateFormat="dd/MM/yyyy hh:mm a"
														amPmAriaLabel="Toggle AM/PM"
														locale={es}
														disableClock={true}
													/>
												</InputGroup>
												<Form.Label>Duracion en minutos</Form.Label>
												<InputGroup className="mb-3" controlid="capacidad">
													<Form.Control
														type="number"
														required
														defaultValue="30"
														min="30"
														onChange={(e) => setDuration(e.target.value)}
													/>
												</InputGroup>
												<Form.Group>
													<Form.Label>Repetir</Form.Label>
													<Form.Control as="select" value={repeatOption} onChange={handleRepeatOptionChange}>
														<option value="none">No se repite</option>
														<option value="daily">Todos los dias</option>
														<option value="weekday">De lunes a viernes</option>
														<option value="weekly">Semanalmente</option>
													</Form.Control>
												</Form.Group>
												{repeatOption === 'daily' || repeatOption === 'weekday' || repeatOption === 'weekly' ? (
													<Form.Group>
														<Form.Label>Fecha de fin</Form.Label>
														<Calendar onChange={setEndDate} value={endDate} clearIcon={null} required={true} />
													</Form.Group>
												) : null}
												{repeatOption === 'weekly' ? (
													<Form.Group>
														<Form.Label>Dias de la semana</Form.Label>
														<div>
															{availableWeekDays.map((day) => (
																<Button
																	key={day}
																	variant={selectedDays.includes(day) ? 'primary' : 'secondary'}
																	value={day}
																	onClick={handleSelectedDaysChange}
																>
																	{day}
																</Button>
															))}
														</div>
													</Form.Group>
												) : null}
												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button type="submit" className="mx-auto" onClick={handleShow}>
														Crear evento
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default NewEvent;
