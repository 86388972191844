import React, { useContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { Button, Modal, Form, Container, Row, Col, Card, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { Store } from '../../Store';
import { getError } from '../../utils';
import LoadingBox from '../../components/LoadingBox';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'FETCH_SUCCESS':
		return { ...state, debts: action.payload, loading: false };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};

function NewPayment() {
	const navigate = useNavigate();
	const { state } = useContext(Store);
	const params = useParams();
	const { id } = params;
	const { userInfo } = state;
	const [debt, setDebt] = useState();
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	const [{ loadingFetch, loadingCreate, debts }, dispatch] = useReducer(reducer, {
		debts: [],
		loadingFetch: true,
		loadingCreate: false,
		error: ''
	});

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await axios.get(`/api/debts/${id}`, {
					headers: { Authorization: `Bearer ${userInfo.token}` }
				});
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		fetchData();
	}, [userInfo.token]);

	const addPaymentHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await axios.post(
				'/api/payments/newPayment',
				{
					debt: debt[0]
				},
				{
					headers: { Authorization: `Bearer ${userInfo.token}` }
				}
			);
			toast.success('Pago creado');
			navigate('/AdminScreen/debts');
		} catch (ex) {
			toast.error(getError(ex));
		}
		handleClose();
	};
	return (
		<div>
			<div>
				{loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
				<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
					<Modal.Header closeButton>
						<Modal.Title>Crear Pago</Modal.Title>
					</Modal.Header>
					<Modal.Body>Seguro desea crear Pago de </Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
              Cancelar
						</Button>
						<Button onClick={addPaymentHandler}>Confirmar</Button>
					</Modal.Footer>
				</Modal>
			</div>
			<Container className="large-container">
				<Helmet>
					<title>Crear Pago</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="border border-3 border-primary"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">Crear Pago</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3 col-lg-12" controlId="deuda">
													<Form.Label>Deuda</Form.Label>
													<Typeahead
														id="debts"
														labelKey={(option) =>
															`${option.user.name} $${option.amount} de fecha ${moment(option.date).format('DD-MM-YYYY')}`
														}
														onChange={setDebt}
														options={debts.filter((debt) => !debt.paid)}
														placeholder="Seleccione la deuda "
														clearButton={true}
														emptyLabel="No hay deudas disponibles"
													></Typeahead>
												</Form.Group>
												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>
													<Button type="submit" className="mx-auto" onClick={handleShow}>
                            Crear Pago
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default NewPayment;
