import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function ConfirmFaqQuestionDelete({ deleteShow, handleClose, question, onHide }) {
	const deleteMethod = async () => {
		try {
			await axios.delete(`/api/faq/${question._id}`);
			toast.success('Pregunta eliminada');
			handleClose();
		} catch (ex) {
			toast.error('Error');
			console.lerror(ex);
		}
	};

	return (
		<Modal show={deleteShow} onHide={onHide} dialogClassName="modal-delete-pay">
			<Modal.Header closeButton>
				<Modal.Title>Borrar pregunta</Modal.Title>
			</Modal.Header>
			<Modal.Body>¿Estás seguro de que quieres eliminar esta pregunta?</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={deleteMethod}>
					Eliminar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
