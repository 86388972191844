import React, { useContext, useReducer, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { Button, Modal, Form, Container, Row, Col, Card, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { Store } from '../../Store';
import { getError } from '../../utils';
import LoadingBox from '../../components/LoadingBox';
import AvatarImageCropper from 'react-avatar-image-cropper';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};

function NewWOD() {
	const navigate = useNavigate();
	const { state } = useContext(Store);
	const { userInfo } = state;
	const [image, setImage] = useState('');
	const [name, setName] = useState();
	const [description, setDescription] = useState();
	const [date, setDate] = useState(new Date());
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);
	const [fileUrl, setFileUrl] = useState(null);

	const [{ loadingFetch, loadingCreate }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		loadingCreate: false,
		error: ''
	});

	const addWODImgHandler = async (file) => {
		try {
			const formData = new FormData();
			formData.append('file', file, `WOD${moment().format('YYYYMMDDhhmmss')}`);
			formData.append('documentId', image);
			const multiPart = { 'content-type': 'multipart/form-data' };
			dispatch({ type: 'CREATE_REQUEST' });
			const response = await axios.post('/api/wod/uploadWodImg', formData, {
				...multiPart,
				headers: { Authorization: `Bearer ${userInfo.token}` }
			});
			const imageId = response.data._id;
			setImage(imageId);
			setFileUrl(response.data.fileLink);
		} catch (ex) {
			toast.error(getError(ex));
		}
	};
	//set hours to 0
	const handleDateChange = (newDate) => {
		newDate.setHours(0, 0, 0, 0);
		setDate(newDate);
	};

	const addWodHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await axios.post(
				'/api/wod',
				{
					name,
					description,
					image,
					date
				},
				{
					headers: { Authorization: `Bearer ${userInfo.token}` }
				}
			);
			toast.success('WOD creado');
			navigate('/AdminScreen/WODs');
		} catch (ex) {
			toast.error(getError(ex));
		}
		handleClose();
	};
	return (
		<div>
			<div>
				{loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
				<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
					<Modal.Header closeButton>
						<Modal.Title>Crear WOD</Modal.Title>
					</Modal.Header>
					<Modal.Body>Seguro desea crear WOD?</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button onClick={addWodHandler}>Confirmar</Button>
					</Modal.Footer>
				</Modal>
			</div>
			<Container className="large-container">
				<Helmet>
					<title>Crear WOD</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="border border-3 border-primary"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
											Crear WOD
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group
													className="mb-3 col-lg-12"
													controlId="nombre"
												>
													<Form.Label>Nombre</Form.Label>
													<Form.Control
														onChange={(e) => setName(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group
													className="mb-3 col-lg-12"
													controlId="descripcion"
												>
													<Form.Label>Descripcion</Form.Label>
													<Form.Control
														as="textarea"
														rows={2}
														onChange={(e) =>
															setDescription(e.target.value)
														}
													></Form.Control>
												</Form.Group>
												<Form.Group
													className="mb-3 col-lg-12"
													controlId="fecha"
												>
													<Form.Label>Fecha</Form.Label>
													<br></br>
													<DateTimePicker
														className="timePicker"
														onChange={handleDateChange}
														value={date}
														clearIcon={null}
														required={true}
														disableClock={true}
														format="dd-MM-y"
														minDate={new Date()}
													/>
												</Form.Group>
												<Form.Group className="mb-3" controlid="image">
													<Form.Label>Imagen:</Form.Label>
													<div className="row align-items-centers">
														<div
															style={{
																padding: 0,
																width: '300px',
																height: '300px',
																margin: 'auto',
																border: '1px solid black',
																backgroundImage:
																	image && fileUrl
																		? 'url(' + fileUrl + ')'
																		: 'url(/images/emptyPhoto.png)',
																backgroundRepeat: 'no-repeat',
																backgroundSize: '100% auto',
																backgroundPosition: 'center top'
															}}
														>
															<AvatarImageCropper
																apply={addWODImgHandler}
																text="Adjuntar la imagen"
															/>
														</div>
													</div>
													{/*fileUrl ? (
                            <div>
                              <div>Preview:</div>
                              <Image
                                style={{
                                  padding: 0,
                                  width: '300px',
                                  height: '300px',
                                  margin: 'auto',
                                  border: '1px solid black',
                                }}
                                key={Date.now()}
                                src={fileUrl ? fileUrl : ''}
                                alt="img"
                              />
                            </div>
                          ) : (
                            <div></div>
                          )*/}
												</Form.Group>
												<Stack direction="horizontal" gap={10}>
													<Button
														id="goBack"
														onClick={() => navigate(-1)}
													>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button
														type="submit"
														className="mx-auto"
														onClick={handleShow}
													>
														Crear WOD
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default NewWOD;
