import React, { createContext, useState } from 'react';

export const ColorContext = createContext();

export const ColorProvider = ({ children }) => {
	const [colors, setColors] = useState({
		primaryColor: '#4b4c4e',
		secondaryColor: '#062665',
		backgroundColor: '#dbdbdb',
		utilsColor: '#006cfa',
		navFontColor: '#ffffff',
		shadowNavColor: '#4b4c4e',
		iconsColor: '#ffffff',
		titlesColor: '#ffffff',
		btnFontColor: '#ffffff',
	});

	const updateColors = (newColors) => {
		setColors((prevColors) => ({
			...prevColors,
			...newColors,
		}));
	};

	return (
		<ColorContext.Provider value={{ colors, updateColors }}>
			{children}
		</ColorContext.Provider>
	);
};
