import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Table, Dropdown, DropdownButton, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { getError, maxitemsPerPage } from '../../utils';
import { Store } from '../../Store';
import moment from 'moment';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination';
import {
	BsTrash,
	BsFileEarmarkRichtext,
	BsFillBackspaceFill,
	BsFillCalendarEventFill
} from 'react-icons/bs';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ATTENDEES_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_ATTENDEES_SUCCESS':
		return { ...state, attendees: action.payload, itemQuantity: action.payload.length };
	case 'FETCH_ATTENDEES_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'FETCH_USER_REQUEST':
		return { ...state };
	case 'FETCH_USER_SUCCESS':
		return { ...state, user: action.payload, loading: false };
	case 'FETCH_USER_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function UserAttendees() {
	const params = useParams();
	const { id } = params;
	const [{ loading, error, attendees, user, successDelete, itemQuantity }, dispatch] = useReducer(
		reducer,
		{
			attendees: [],
			user: [],
			itemQuantity: 0,
			loading: true,
			error: ''
		}
	);

	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = attendees.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = attendees.slice(indexOfFirstPost, indexOfLastPost);
	};
	useEffect(() => {
		const fetchAttendeesData = async () => {
			try {
				dispatch({ type: 'FETCH_ATTENDEES_REQUEST' });
				const { data } = await axios.get(`/api/eventattendee/user/${id}`, {
					headers: { Authorization: `Bearer ${userInfo.token}` }
				});
				dispatch({ type: 'FETCH_ATTENDEES_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_ATTENDEES_FAIL', payload: getError(err) });
			}
		};
		const fetchUserData = async () => {
			try {
				dispatch({ type: 'FETCH_USER_REQUEST' });
				const { data } = await axios.get(`/api/users/${id}`, {
					headers: { Authorization: `Bearer ${userInfo.token}` }
				});
				dispatch({ type: 'FETCH_USER_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_USER_FAIL', payload: getError(err) });
			}
		};
		/*if (successDelete) {
      dispatch({ type: 'DELETE_RESET' });
    } else {*/
		fetchAttendeesData();
		fetchUserData();
		//}
	}, [successDelete, userInfo.token, id]);

	async function detailsEventHandler(user) {
		navigate(`/AdminScreen/detailEvent/${user._id}`);
	}

	async function deleteEventHandler(event) {
		if (window.confirm('Seguro desea eliminar la inscripción?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await axios.delete('/api/eventattendee/unsubscribe', {
					headers: { authorization: `Bearer ${userInfo.token}` },
					data: { user, event }
				});
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Inscripcion Eliminada');
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Usuarios</title>
			</Helmet>
			<div className="container admin-con">
				<div className="tableResponsive">
					<Row style={{ margin: '5% 0 2.5%' }}>
						<Col>
							<h1 className="text-right">
								<BsFillCalendarEventFill></BsFillCalendarEventFill>Lista de Inscripciones de: {user.name}
							</h1>
						</Col>
						<Col
							style={{
								display: 'flex',
								justifyContent: 'flex-end'
							}}
						>
							<Button className="btn btn-dark m-1 fixed-left" value="Volver" onClick={() => navigate(-1)}>
								<span>
									<BsFillBackspaceFill /> Volver
								</span>
							</Button>
						</Col>
					</Row>
					<Card>
						{loading ? (
							<LoadingBox></LoadingBox>
						) : error ? (
							<MessageBox variant="danger">{error}</MessageBox>
						) : (
							<Table responsive bordered hover size="sm">
								<thead>
									<tr>
										<th className="col-md-2">
											<span>Tema</span>
										</th>
										<th className="col-md-2">Locacion</th>
										<th className="col-md-1">Capacidad</th>
										<th className="col-md-1">Fecha y hora de inicio</th>
										<th className="col-md-1">Fecha y hora de fin</th>
										<th className="col-md-2"></th>
									</tr>
								</thead>
								{attendees
									? currentPosts.map((event) => (
										<tbody key={event._id}>
											<tr
												className={`align-items-center table-order ${
													event.isActive ? 'table-success' : 'table-secondary'
												}`}
												id="data"
											>
												<td className="col-md-2">{event.subject}</td>
												<td className="col-md-2">{event.location}</td>
												<td className="col-md-1">{event.capacity}</td>
												<td className="col-md-1">
													{moment(event.eventStartDateTime).format('DD/MM/yyyy HH:mm')}
												</td>
												<td className="col-md-1">
													{moment(event.eventEndDateTime).format('DD/MM/yyyy HH:mm')}
												</td>
												<td>
													<DropdownButton id="optionsLists" drop="start" title="">
														<Dropdown.Item eventKey="0" onClick={() => detailsEventHandler(event)}>
															<BsFileEarmarkRichtext />
																Ver Detalles
														</Dropdown.Item>
														<Dropdown.Item eventKey="1" onClick={() => deleteEventHandler(event)}>
															<BsTrash />
																Eliminar
														</Dropdown.Item>
													</DropdownButton>
												</td>
											</tr>
										</tbody>
									))
									: ''}
							</Table>
						)}
					</Card>
					<Pagination
						className="pagination-bar"
						totalCount={itemQuantity}
						onPageChange={onPageChange}
						currentPage={currentPage}
						pageSize={maxitemsPerPage}
					></Pagination>
				</div>
			</div>
		</div>
	);
}

export default UserAttendees;
