import React, { useContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { Button, Modal, Form, Container, Row, Col, Card, Stack, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { Store } from '../../Store';
import { getError } from '../../utils';
import LoadingBox from '../../components/LoadingBox';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST_DATA':
		return { ...state, loadingData: true };
	case 'FETCH_SUCCESS_DATA':
		return { ...state, bookingtemplates: action.payload, loadingData: false };
	case 'FETCH_FAIL_DATA':
		return { ...state, loading: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function AddUserBooking() {
	const params = useParams();
	const { id } = params;
	const navigate = useNavigate();
	const { state } = useContext(Store);
	const { userInfo } = state;
	const [bookingtemplate, setBookingTemplate] = useState('');
	const [startingDate, setStartingDate] = useState(new Date());
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);
	const [paymentConfirmed, setPaymentConfirmed] = useState(false);

	const [{ loadingFetch, loadingCreate, bookingtemplates, loadingData }, dispatch] = useReducer(
		reducer,
		{
			bookingtemplates: [],
			loadingFetch: true,
			loadingCreate: false,
			loadingData: true,
			error: ''
		}
	);
	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST_DATA' });
				const { data } = await axios.get('/api/bookings/getactive', {
					headers: { Authorization: `Bearer ${userInfo.token}` }
				});
				dispatch({ type: 'FETCH_SUCCESS_DATA', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL_DATA', payload: getError(err) });
			}
		};
		fetchData();
	}, [userInfo.token]);

	const handlePaymentConfirmation = () => {
		setPaymentConfirmed(!paymentConfirmed);
	};

	const addUserHandler = async () => {
		if (bookingtemplate === '' && bookingtemplates.length > 0) {
			setBookingTemplate(bookingtemplates[0]._id);
		}
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await axios.post(
				'/api/userbookings',
				{
					user: id,
					template: bookingtemplate === '' ? bookingtemplates[0]._id : bookingtemplate,
					startingDate: startingDate,
					paymentConfirmed: paymentConfirmed // Agregar el estado del pago confirmado
				},
				{
					headers: { Authorization: `Bearer ${userInfo.token}` }
				}
			);
			toast.success('Cuponera asignada');
			navigate(`/AdminScreen/userbookinglist/${id}`);
		} catch (ex) {
			toast.error(getError(ex));
		}
	};
	return (
		<div>
			<div>
				{loadingCreate && loadingFetch && loadingData && <LoadingBox></LoadingBox>}
				<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
					<Modal.Header closeButton>
						<Modal.Title>Asignar Cuponera</Modal.Title>
					</Modal.Header>
					<Modal.Body>Seguro desea asignar esta cuponera?</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button onClick={addUserHandler}>Confirmar</Button>
					</Modal.Footer>
				</Modal>
			</div>
			<Container className="large-container">
				<Helmet>
					<title>Asignar Cuponera</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="border border-3 border-primary"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">Asignar Cuponera</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3" controlid="bookingtemplate">
													<Form.Label>Cuponera:</Form.Label>
													<Form.Select
														// as='select'
														required
														onChange={(e) => setBookingTemplate(e.target.value)}
													>
														{bookingtemplates.map((booking) => (
															<option value={booking._id} key={booking._id}>
																{booking.name} | ${booking.price}
																{' | '}
																{booking.totalBookings} clases por {booking.daysLast} dias
															</option>
														))}
													</Form.Select>
												</Form.Group>
												<Form.Group>
													<Form.Label>Fecha de inicio para cuponera</Form.Label>
													<InputGroup
														className="d-flex col-12 justify-content-center align-items-center"
														controlid="startingDate"
													>
														<br></br>
														<DatePicker
															containerClassName="form-control"
															className="form-control"
															onChange={setStartingDate}
															selected={startingDate}
															value={startingDate}
															clearIcon={null}
															minDate={new Date()}
															required={true}
															locale="es"
															dateFormat="dd-MM-yyyy"
														/>
													</InputGroup>
													<br />
												</Form.Group>
												<Stack direction="horizontal" gap={10}>
													<input type="checkbox" onClick={handlePaymentConfirmation} />
													Marcar Pago
													<Button type="submit" className="mx-auto" onClick={handleShow}>
														Asignar cuponera
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default AddUserBooking;
