import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import React, {
	useContext,
	useState,
	useReducer,
	useLayoutEffect,
} from 'react';
import axios from 'axios';
import { Store } from '../../Store';
import LinkContainer from 'react-router-bootstrap/LinkContainer';
import AdminNavbarOptions from '../AdminNavbarOptions/AdminNavbarOptions';
import NavbarToggler from '../NavbarToggler/NavbarToggler';
import '../../App.css';
import './AdminNavbar.css';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';


const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_COMPANY_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_COMPANY_SUCCESS':
		return { ...state, GotaSettings: action.payload, loading: false };
	case 'FETCH_COMPANY_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function AdminNavbar() {
	const [{ GotaSettings }, dispatch] = useReducer(reducer, {
		GotaSettings: {},
		loading: true,
		error: ''
	});
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();
	const [width, setWidth] = useState('');

	useLayoutEffect(() => {
		window.addEventListener('resize', () => {
			if (window.innerWidth > 992) {
				setActiveCollapse(true);
			}
		});
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_COMPANY_REQUEST' });
				const { data } = await axios.get('/api/config/public');
				setWidth(window.innerWidth);
				dispatch({ type: 'FETCH_COMPANY_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_COMPANY_FAIL', payload: err.message });
			}
		};
		fetchData();
	}, [width]);

	const signoutHandler = async () => {
		await ctxDispatch({ type: 'USER_SIGNOUT' });
		localStorage.removeItem('userInfo');
		navigate('/signin');
	};

	const [isActiveCollapse, setActiveCollapse] = useState(true);

	const handleToggleCollapse = () => {
		setActiveCollapse(!isActiveCollapse);
	};
	const collapse = () => {
		setActiveCollapse(true);
	};

	if (!userInfo || (userInfo && !userInfo.isAdmin)) {
		return null;
	}

	return (
		<div>
			<ToastContainer position="bottom-center" limit={1}></ToastContainer>
			<header className="header-admin site-header">
				<Navbar className="container-fluid admin-navbar" expand="d-none ">
					<NavbarToggler onClick={handleToggleCollapse} className="admin-toggler" />
					{GotaSettings && GotaSettings.image && GotaSettings.image.fileLink && (
						<Navbar.Brand className="mx-auto">
							<Link className="" to="/">
								<img
									src={
										GotaSettings &&
											GotaSettings.image &&
											GotaSettings.image.fileLink
											? GotaSettings.image.fileLink
											: null
									}
									alt="Company logo"
								/>
							</Link>
						</Navbar.Brand>
					)}
					<Navbar className="navbar-collapse-Admin">
						{isActiveCollapse ? (
							<AdminNavbarOptions handleCollapse={collapse} />
						) : (
							<Modal
								backdropClassName="collapsable-navbar-backdrop"
								show={!isActiveCollapse}
								dialogClassName="collapsable-navbar-extended"
							>
								<Row className="justify-content-end mt-3 w-100">
									<button
										className="col-md-1 col-sm-2 col-3 exit-menu"
										onClick={collapse}
									>
										<FaTimes />
									</button>
								</Row>
								<AdminNavbarOptions handleCollapse={collapse} />
							</Modal>
						)}
					</Navbar>

					{userInfo ? (
						<NavDropdown
							title={userInfo.name}
							id="basic-nav-drospdown"
							className="mx-2"
						>
							<LinkContainer to={'/AdminScreen/profile'}>
								<NavDropdown.Item>Mi Perfil</NavDropdown.Item>
							</LinkContainer>
							<NavDropdown.Divider />
							<li className="rightlinks">
								{' '}
								<Link
									className="dropdown-item"
									to="#signout"
									onClick={signoutHandler}
								>
									<i className="fa fa-sign-out " aria-hidden="true"></i>
									Salir
								</Link>
							</li>
						</NavDropdown>
					) : (
						<li className="rightlinks">
							{' '}
							<Link className="nav-link" to="/signin">
								<i className="fa fa-user" aria-hidden="true"></i>
								<span className="d-none d-lg-block">Ingresar</span>
							</Link>
						</li>
					)}
				</Navbar>
			</header>
			<div></div>
		</div>
	);
}

export default AdminNavbar;
