import Axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../../Store';
import { toast } from 'react-toastify';
import { getError } from '../../utils';
import { BsFillEyeFill } from 'react-icons/bs';
import { BsFillEyeSlashFill } from 'react-icons/bs';
import InputGroup from 'react-bootstrap/InputGroup';
import '../../App.css';
import { Col, Button, Row, Container, Card, Form } from 'react-bootstrap';

export default function SignInScreen() {
	const navigate = useNavigate();
	const { search } = useLocation();
	const redirectInUrl = new URLSearchParams(search).get('redirect');
	const redirect = redirectInUrl ? redirectInUrl : '/';

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordType, setPasswordType] = useState('password');

	const togglePassword = () => {
		if (passwordType === 'password') {
			setPasswordType('text');
			return;
		}
		setPasswordType('password');
	};

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const submitHandler = async (e) => {
		e.preventDefault();
		try {
			const { data } = await Axios.post('/api/users/signin', {
				email,
				password
			});
			ctxDispatch({ type: 'USER_SIGNIN', payload: data });
			localStorage.setItem('userInfo', JSON.stringify(data));
			data.isAdmin ? navigate('/AdminScreen/Home') : navigate('/');
		} catch (err) {
			toast.error(getError(err));
		}
	};

	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
		}
	}, [navigate, redirect, userInfo]);

	return (
		<Container>
			<Helmet>
				<title>Iniciar sesión</title>
			</Helmet>
			<Container fluid>
				<Row className="mt-5 mb-0 d-flex justify-content-center align-items-center">
					<Col md={8} lg={6} xs={12}>
						<div className="borderLine"></div>
						<Card className="shadow">
							<Card.Body>
								<div className="mb-3 mt-md-4">
									<h2 className="fw-bold mb-4 text-uppercase text-align-center ">Iniciar Sesión</h2>
									<div className="mb-3">
										<Form onSubmit={submitHandler}>
											<Form.Group className="mb-3" controlId="email">
												<Form.Label className="text-center">E-mail</Form.Label>
												<Form.Control
													type="email"
													placeholder="Enter email"
													onChange={(e) => setEmail(e.target.value.toLowerCase())}
													required
												/>
											</Form.Group>

											<Form.Label>Contraseña</Form.Label>
											<InputGroup className="mb-3" controlId="password">
												<Form.Control
													type={passwordType}
													required
													onChange={(e) => setPassword(e.target.value)}
												/>
												<Button
													variant="btn btn--secondary"
													className="eyeButton"
													id="button-addon2"
													onClick={togglePassword}
												>
													{passwordType === 'password' ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
												</Button>
											</InputGroup>
											<Form.Group className="mb-3" controlId="formBasicCheckbox">
												<p className="small">
													<a className="text-primary" href="/reset-password">
														Olvidó su contraseña?
													</a>
												</p>
											</Form.Group>
											<div className="d-grid">
												<Button variant="primary" type="submit">
													Iniciar Sesión
												</Button>
											</div>
										</Form>
										<div className="mt-3">
											<p className="mb-0  text-center">
												No está registrado?{' '}
												<a href="/signup" className="text-primary fw-bold">
													Registrarse
												</a>
											</p>
										</div>
									</div>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>

			{/* <h1 className="my-3">Iniciar Sesión</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>E-mail</Form.Label>
          <Form.Control
            type="email"
            required
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          ></Form.Control>
        </Form.Group>
        <Form.Label>Contraseña</Form.Label>
        <InputGroup className="mb-3" style={{padding: 0}} controlId="password">
        <Form.Control
            type={passwordType}
            required
            onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="btn btn--secondary" className='eyeButton' id="button-addon2" onClick={togglePassword}>
        { passwordType==="password"?<BsFillEyeSlashFill/>  :<BsFillEyeFill/> }
        </Button>
      </InputGroup>
           <div className="mb-3">
          <Link to={`/reset-password`}>Olvide mi contraseña</Link>
        </div>
        <div className="mb-3">
          <Button type="submit">Iniciar Sesion</Button>
        </div>
        <div className="mb-3">
          Nuevo cliente?{" "}
          <Link to={`/signup?redirect=${redirect}`}>Crea una cuenta</Link>
        </div>
      </Form> */}
		</Container>
	);
}
