import LoadingBox from '../../components/LoadingBox';
import React, { useContext, useReducer, useState, useEffect } from 'react';
import { Store } from '../../Store';
import { Helmet } from 'react-helmet-async';
import { Form, Button, Modal, Col, Container, Row, Card, Stack, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getError } from '../../utils';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, event: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditEvent() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, event }, dispatch] = useReducer(reducer, {
		event: {},
		loading: true,
		error: ''
	});
	const { state } = useContext(Store);
	const { userInfo } = state;
	const [tema, setTema] = useState('');
	const [locacion, setLocacion] = useState('');
	const [capacidad, setCapacidad] = useState('');
	const [fechaInicio, setFechaInicio] = useState(new Date());
	const [fechaFin, setFechaFin] = useState(new Date());
	const [duration, setDuration] = useState(30);
	const [isActive, setIsActive] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);
	const handleIsActive = () => {
		setIsActive(!isActive);
	};

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await axios.get(`/api/event/find/${id}`, {
					headers: { Authorization: `Bearer ${userInfo.token}` }
				});
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setTema(result.data.subject);
				setLocacion(result.data.location);
				setCapacidad(result.data.capacity);
				setFechaInicio(new Date(result.data.eventStartDateTime));
				setFechaFin(new Date(result.data.eventEndDateTime));
				setDuration(result.data.duration);
				setIsActive(result.data.isActive);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchData();
	}, [id]);

	const editEvent = async () => {
		try {
			if (fechaFin < fechaInicio) {
				toast.error('La fecha y hora de fin debe despues de la de inicio');
				handleClose();
				return;
			}
			let body = {
				eventStartDateTime: fechaInicio,
				eventStartDate: moment(fechaInicio).format('DD/MM/yyyy'),
				eventStartTime: moment(fechaInicio).format('HH:mm'),
				duration: duration,
				capacity: capacidad,
				isActive: isActive
			};
			if (locacion !== '') {
				body = { ...body, location: locacion };
			}
			if (tema !== '') {
				body = { ...body, subject: tema };
			}
			dispatch({ type: 'CREATE_REQUEST' });
			await axios.put(`/api/event/${event._id}`, body, {
				headers: { Authorization: `Bearer ${userInfo.token}` }
			});
			dispatch({
				type: 'UPDATE_SUCCESS'
			});
			toast.success('BookingTemplate actualizado Correctamente');
			navigate('/AdminScreen/eventList');
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL' });
			toast.error(getError(err));
		}
	};

	return (
		<div>
			{loading && <LoadingBox></LoadingBox>}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Guardar Cambios</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea guardar los cambios?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancelar
					</Button>
					<Button onClick={editEvent}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<Container className="large-container">
				<Helmet>
					<title>Editar evento</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="border border-3 border-primary"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">Editar evento</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3" controlid="name">
													<Form.Label>Tema</Form.Label>
													<Form.Control
														type="text"
														required
														defaultValue={event.subject}
														onChange={(e) => setTema(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group className="mb-3" controlid="precio">
													<Form.Label>Locacion</Form.Label>
													<Form.Control
														type="text"
														required
														defaultValue={event.location}
														onChange={(e) => setLocacion(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group className="mb-3" controlid="duracion">
													<Form.Label>Capacidad</Form.Label>
													<Form.Control
														type="number"
														required
														defaultValue={event.capacity}
														onChange={(e) => setCapacidad(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Label>Fecha de inicio</Form.Label>
												<InputGroup className="mb-3" controlid="fechaInicio">
													<br></br>
													<DateTimePicker
														onChange={setFechaInicio}
														value={fechaInicio}
														clearIcon={null}
														required={true}
													/>
												</InputGroup>
												<Form.Label>Duracion en minutos</Form.Label>
												<InputGroup className="mb-3" controlid="capacidad">
													<Form.Control
														type="number"
														required
														defaultValue="30"
														min="30"
														onChange={(e) => setDuration(e.target.value)}
													/>
												</InputGroup>
												<Form.Group className="mb-3 col-3" controlId="isActive">
													<Form.Label>Esta activo?</Form.Label>
													<Form.Check
														type="checkbox"
														defaultChecked={event.isActive}
														onClick={handleIsActive}
														onTouchEnd={handleIsActive}
													></Form.Check>
												</Form.Group>

												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>
													<Button type="submit" className="mx-auto" onClick={handleShow}>
														Guardar Cambios
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default EditEvent;
