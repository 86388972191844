import React from 'react';
import { FaHome, FaUsers, FaCog, FaChartBar } from 'react-icons/fa';
import { RiCoupon2Fill } from 'react-icons/ri';
import { BsFillFileEarmarkFill, BsFillCalendarEventFill } from 'react-icons/bs';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './AdminNavbarOptions.css';

export default function AdminNavbarOptions({ handleCollapse }) {
	return (
		<ul className="admin-navbar-options">
			<li>
				<Link className="nav-link" to="/AdminScreen" onClick={handleCollapse}>
					<FaChartBar />
					<h3>Admin Inicio</h3>
				</Link>
			</li>

			<li>
				<Link className="nav-link" to="/AdminScreen/users" onClick={handleCollapse}>
					<FaUsers />
					<h3>Usuarios</h3>
				</Link>
			</li>

			<li>
				<Link className="nav-link" to="/AdminScreen/bookings" onClick={handleCollapse}>
					<RiCoupon2Fill />
					<h3>Cuponeras</h3>
				</Link>
			</li>
			<li>
				<Link className="nav-link" to="/AdminScreen/WODs" onClick={handleCollapse}>
					<BsFillFileEarmarkFill />
					<h3>WOD´s</h3>
				</Link>
			</li>

			<li>
				<Link className="nav-link" to="/AdminScreen/eventList" onClick={handleCollapse}>
					<BsFillCalendarEventFill />
					<h3>Calendario</h3>
				</Link>
			</li>

			<li>
				<Link className="nav-link" to="/AdminScreen/debts" onClick={handleCollapse}>
					<FaFileInvoiceDollar />
					<h3>Deudas</h3>
				</Link>
			</li>
			<li>
				{' '}
				<Link className="nav-link" to="/AdminScreen/Home" onClick={handleCollapse}>
					<FaHome />
					<h3>Inicio</h3>
				</Link>
			</li>

			<li>
				<Link to="/AdminScreen/Config" onClick={handleCollapse} className="nav-link">
					<FaCog />
					<h3>Configuracion</h3>
				</Link>
			</li>
		</ul>
	);
}
