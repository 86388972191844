import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import React, {
	useContext,
	useLayoutEffect,
	useState,
	useReducer,
} from 'react';
import { Store } from '../Store';
import axios from 'axios';
import '../App.css';
import './Navbar.css';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import NavbarOptions from './NavbarOptions/NavbarOptions';
import NavbarToggler from './NavbarToggler/NavbarToggler';
import LinkContainer from 'react-router-bootstrap/LinkContainer';
import { FaTimes } from 'react-icons/fa';
const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_COMPANY_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_COMPANY_SUCCESS':
		return { ...state, GotaSettings: action.payload, loading: false };
	case 'FETCH_COMPANY_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function Navbarr() {
	const [{ GotaSettings }, dispatch] = useReducer(reducer, {
		GotaSettings: {},
		loading: true,
		error: '',
	});

	useLayoutEffect(() => {
		window.addEventListener('resize', () => {
			if (window.innerWidth > 992) {
				setActiveCollapse(true);
			}
		});
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_COMPANY_REQUEST' });
				const { data } = await axios.get('/api/config/public');
				dispatch({ type: 'FETCH_COMPANY_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_COMPANY_FAIL', payload: err.message });
			}
		};
		// const fetchNotificationsData = async () => {
		//   try {
		//     const { data } = await axios.get(
		//       `/api/notifications/lastten/${userInfo._id}`,
		//       {
		//         headers: { authorization: `Bearer ${userInfo.token}` },
		//       }
		//     );
		//     data.forEach(function (notificacion) {
		//       var newNotification = {
		//         image: "https://gotabooking-demo.s3.sa-east-1.amazonaws.com/gotabooking-logo-black.png",
		//         message: notificacion.message,
		//         /*detailPage: '/events',*/
		//         receivedTime: moment(notificacion.date).format("DD/MM/yyyy HH:mm"),
		//       };
		//       setNotifications((notifications) => [
		//         ...notifications,
		//         newNotification,
		//       ]);
		//       console.log(newNotification);
		//     });
		//   } catch (err) {
		//     console.log(getError(err));
		//   }
		// };
		fetchData();
		//fetchNotificationsData();
	}, [] );

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();
	const signoutHandler = async () => {
		await ctxDispatch({ type: 'USER_SIGNOUT' });
		localStorage.removeItem('userInfo');
		navigate('/');
	};

	//#region NAVBAR COLLAPSE METHODS
	const [isActiveCollapse, setActiveCollapse] = useState(true);

	const handleToggleCollapse = () => {
		setActiveCollapse(!isActiveCollapse);
	};
	const collapse = () => {
		setActiveCollapse(true);
	};
	//#endregion

	const location = useLocation();
	const route = location.pathname;

	if (route.includes('AdminScreen') || (userInfo && userInfo.isAdmin)) return null;

	return (
		<div>
			<ToastContainer position="bottom-center" limit={1}></ToastContainer>
			<header className="header-admin site-header">
				<Navbar className="container-fluid admin-navbar" expand="d-none ">
					<NavbarToggler onClick={handleToggleCollapse} className="admin-toggler" />
					{GotaSettings && GotaSettings.image && GotaSettings.image.fileLink && (
						<Navbar.Brand className="mx-auto">
							<Link className="" to="/">
								<img
									src={
										GotaSettings &&
											GotaSettings.image &&
											GotaSettings.image.fileLink
											? GotaSettings.image.fileLink
											: null
									}
									alt="Company logo"
								/>
							</Link>
						</Navbar.Brand>
					)}
					<Navbar className="navbar-collapse-Admin">
						{isActiveCollapse ? (
							<NavbarOptions handleCollapse={collapse} userInfo={userInfo} />
						) : (
							<Modal
								backdropClassName="collapsable-navbar-backdrop"
								show={!isActiveCollapse}
								dialogClassName="collapsable-navbar-extended"
							>
								<Row className="justify-content-end mt-3 w-100">
									<button
										className="col-md-1 col-sm-2 col-3 exit-menu"
										onClick={collapse}
									>
										<FaTimes />
									</button>
								</Row>
								<NavbarOptions handleCollapse={collapse} userInfo={userInfo} />
							</Modal>
						)}
					</Navbar>
					{/* {userInfo ? (
						<Navbar className='navbar-collapse-Admin'>
							{isActiveCollapse ? (
								<NavbarOptions handleCollapse={collapse} userInfo={userInfo} />
							) : (
								<Modal
									backdropClassName='collapsable-navbar-backdrop'
									show={!isActiveCollapse}
									dialogClassName='collapsable-navbar-extended'
								>
									<Row className='justify-content-end mt-3 w-100'>
										<button className='col-md-1 col-sm-2 col-3 exit-menu' onClick={collapse}>
											<FaTimes />
										</button>
									</Row>
									<NavbarOptions handleCollapse={collapse} userInfo={userInfo} />
								</Modal>
							)}
						</Navbar>
					) : null} */}

					{userInfo ? (
						<NavDropdown
							title={userInfo.name}
							id="basic-nav-drospdown"
							className="mx-2"
						>
							<LinkContainer
								to={userInfo.isAdmin ? '/AdminScreen/profile' : '/profile'}
							>
								<NavDropdown.Item>Mi Perfil</NavDropdown.Item>
							</LinkContainer>
							{/* {userInfo && !userInfo.isAdmin ? ( */}
							<LinkContainer to={'/useravailableevents'}>
								<NavDropdown.Item>Reservar clase</NavDropdown.Item>
							</LinkContainer>
							{/* ) : (
									<div></div>
								)} */}

							<NavDropdown.Divider />
							<li className="rightlinks">
								{' '}
								<Link
									className="dropdown-item"
									to="#signout"
									onClick={signoutHandler}
								>
									<i className="fa fa-sign-out " aria-hidden="true"></i>
									Salir
								</Link>
							</li>
						</NavDropdown>
					) : (
						<li className="rightlinks">
							{' '}
							<Link className="nav-link" to="/signin">
								<i className="fa fa-user" aria-hidden="true"></i>
								<span className="d-none d-lg-block">Ingresar</span>
							</Link>
						</li>
					)}
				</Navbar>
			</header>
			<div></div>
		</div>
	);
}

export default Navbarr;
