import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Table, Dropdown, DropdownButton, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { getError, maxitemsPerPage } from '../../utils';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination';
import { BsPlusCircle, BsTrash, BsPencilSquare, BsFileEarmarkRichtext } from 'react-icons/bs';
import { RiCoupon2Fill } from 'react-icons/ri';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			bookings: action.payload,
			loading: false,
			itemQuantity: action.payload.length
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function BookingList() {
	const [{ loading, error, bookings, successDelete, itemQuantity }, dispatch] = useReducer(reducer, {
		bookings: [],
		loading: true,
		error: '',
		itemQuantity: 0
	});

	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = bookings.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = bookings.slice(indexOfFirstPost, indexOfLastPost);
	};
	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await axios.get('/api/bookings', {
					headers: { Authorization: `Bearer ${userInfo.token}` }
				});
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete, userInfo.token]);

	async function deleteBookingTemplateHandler(booking) {
		if (
			window.confirm(`Seguro desea ${booking.isActive ? 'desactivar' : 'activar'} esta cuponera?`)
		) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await axios.put(
					`/api/bookings/toggleactive/${booking._id}`,
					{},
					{
						headers: { authorization: `Bearer ${userInfo.token}` }
					}
				);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success(`Cuponera ${booking.name} ${booking.isActive ? 'desactivada' : 'activada'}`);
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}

	async function editBookingTemplateHandler(bookingTemplate) {
		navigate(`/AdminScreen/editBookingTemplate/${bookingTemplate._id}`);
	}

	async function detailsBookingTemplateHandler(bookingTemplate) {
		navigate(`/AdminScreen/editBookingTemplate/${bookingTemplate._id}`);
	}

	async function newBookingTemplateHandler() {
		navigate('/AdminScreen/newBookingTemplate');
	}

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Cuponeras</title>
			</Helmet>

			<div className="container admin-con">
				<div className="tableResponsive">
					<Row style={{ margin: '5% 0 2.5%' }}>
						<Col>
							<h1 className="text-right">
								<RiCoupon2Fill></RiCoupon2Fill>Lista de Cuponeras
							</h1>
						</Col>
						<Col
							style={{
								display: 'flex',
								justifyContent: 'flex-end'
							}}
						>
							<Button
								className="btn btn-dark m-1 fixed-right"
								value="Crear cuponera"
								onClick={() => newBookingTemplateHandler()}
							>
								<span>
									<BsPlusCircle /> Crear Cuponera
								</span>
							</Button>
						</Col>
					</Row>
					{loading ? (
						<LoadingBox></LoadingBox>
					) : error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : (
						<Card>
							<Table bordered hover responsive size="sm">
								<thead>
									<th className="col-md-2">
										<span>Nombre</span>
									</th>
									<th className="col-md-2">Precio</th>
									<th className="col-md-1">Duracion</th>
									<th className="col-md-1">Cupos</th>
									<th className="col-md-1">Estado</th>
									<th className="col-1">Opciones</th>
								</thead>
								{bookings
									? currentPosts.map((booking) => (
										<tbody key={booking._id}>
											<tr className="align-items-center table-order" id="data" key={booking._id}>
												<td className="col-md-2">{booking.name}</td>
												<td className="col-md-2">{booking.price}</td>
												<td className="col-md-2">{booking.daysLast}</td>
												<td className="col-md-2">{booking.totalBookings}</td>
												<td className="col-md-2">{`${booking.isActive ? 'activa' : 'inactiva'}`}</td>
												<td>
													<DropdownButton id="optionsLists" drop="start" title="">
														<Dropdown.Item eventKey="0" onClick={() => detailsBookingTemplateHandler(booking)}>
															<BsFileEarmarkRichtext />
																Ver Detalles
														</Dropdown.Item>
														<Dropdown.Item eventKey="1" onClick={() => editBookingTemplateHandler(booking)}>
															<BsPencilSquare />
																Editar
														</Dropdown.Item>
														<Dropdown.Item eventKey="2" onClick={() => deleteBookingTemplateHandler(booking)}>
															<BsTrash />
																Eliminar
														</Dropdown.Item>
													</DropdownButton>
												</td>
											</tr>
										</tbody>
									))
									: ''}
							</Table>
						</Card>
					)}
					<Pagination
						className="pagination-bar"
						totalCount={itemQuantity}
						onPageChange={onPageChange}
						currentPage={currentPage}
						pageSize={maxitemsPerPage}
					></Pagination>
				</div>
			</div>
		</div>
	);
}

export default BookingList;
