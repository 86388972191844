import React, { useContext, useReducer, useState } from 'react';
import { Store } from '../../Store';
import { Helmet } from 'react-helmet-async';
import { Form, Button, Modal, Col, Container, Row, Card, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getError } from '../../utils';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';

const reducer = (state, action) => {
	switch (action.type) {
	case 'UPDATE_REQUEST':
		return { ...state, loadingUpdate: true };
	case 'UPDATE_SUCCESS':
		return { ...state, loadingUpdate: false };
	case 'UPDATE_FAIL':
		return { ...state, loadingUpdate: false };
	default:
		return state;
	}
};

function UserDataConfig() {
	const navigate = useNavigate();

	const [dispatch] = useReducer(reducer, {
		user: {},
		loading: true,
		error: ''
	});
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [name, setName] = useState(userInfo.name);
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	const submitHandler = async (e) => {
		e.preventDefault();
		if (name === '') {
			toast.error('El campo nombre no debe ser vacio');
		} else {
			try {
				const { data } = await axios.put(
					`/api/users/${userInfo._id}`,
					{
						name
					},
					{ headers: { authorization: `Bearer ${userInfo.token}` } }
				);
				dispatch({
					type: 'UPDATE_SUCCESS'
				});
				ctxDispatch({ type: 'USER_SIGNIN', payload: data });
				localStorage.setItem('userInfo', JSON.stringify(data));
				navigate('/profile');
				toast.success('Cambio de contraseña exitoso');
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL' });
				toast.error(getError(err));
			}
		}
	};

	return (
		<div>
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Guardar Cambios</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea guardar los cambios?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancelar
					</Button>
					<Button onClick={submitHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<Container className="large-container">
				<Helmet>
					<title>Editar Usuario</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="border border-3 border-primary"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">Editar Usuario</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3" controlid="name">
													<Form.Label>Nombre:</Form.Label>
													<Form.Control
														type="text"
														required
														defaultValue={name}
														onChange={(e) => setName(e.target.value)}
													></Form.Control>
												</Form.Group>

												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>
													<Button type="submit" className="mx-auto" onClick={handleShow}>
														Guardar Cambios
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default UserDataConfig;
